import * as React from "react"

import Container from "../components/container"
import AnimationWrapper from "../components/animationWrapper"
import Button from "../components/button"
import Seo from "../components/seo"
import Layout from "../components/layout"

const NotFoundPage = ( {location} ) => (

  <Layout location={location}>
  <Seo title="404: Nie odnaleziono strony - Transpol Lider" />


  <section id="success-description" className="page-section">
    <AnimationWrapper>
      <Container>
        <h1 className="section-title">
          Błąd 404: Nie odnaleziono strony
        </h1>
        <p className="section-description">
        Niestety strona o podanym linku nie istnieje :(
        </p>
        <Button btnStyle="dark" btnLink="/" btnText="Przejdź na stronę główną" />
      </Container>
    </AnimationWrapper>
  </section>

</Layout>
)

export default NotFoundPage